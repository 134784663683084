import React, { useState } from "react";
import {
  Tab,
  Tabs,
  AppBar,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-scroll";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      backgroundColor: "transparent",
      borderBottom: "1px solid #e8e8e8",
      height: 55
    },
    tabs: {
      backgroundColor: "transparent",
      borderBottom: "1px solid #e8e8e8",
      color: "black",
      justifyItems: "center",
      height: 55
    },
    tab: {
      fontSize: 29,
      fontWeight: "720",
    },
    tooltip: {
      fontSize: 25,
    },
  })
);

export default () => {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const labels = ["Home", "About", "Projects", "Trips", "Referrals"];

  return (
    <AppBar className={classes.appBar} position="fixed" elevation={24}>
      <Tabs className={classes.tabs} onChange={handleChange} value={value} variant="scrollable">
        {labels.map((label, i) => (
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            duration={500}
            to={`${label.toLowerCase()}`}
            key={i}
          >
            <Tab label={label} key={i} className={classes.tab} />
          </Link>
        ))}
      </Tabs>
    </AppBar>
  );
};
