import React from 'react';
import { Home } from './components/home/home';
import About from './components/about/about';
import Projects from './components/projects/projects';
import Referrals from './components/referrals/referrals';
import AppBar from './components/appbar/appBar';
import './App.css';
import Trips from './components/trips/trips';

function App() {
  return (
    <div className="App">
      <AppBar/>
      <Home/>
      <About/>
      <Projects />
      <Trips />
      <Referrals />
    </div>
  );
}

export default App;
