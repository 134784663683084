import React from "react";
import GooseberryFalls from "../../images/GooseberryFalls.jpg";
import "../../App.css";

const space = "  |  ";

export const Home = () => {
  return (
    <div className="Home" id="home">
      <img src={GooseberryFalls} className="Profile-pic" alt="photos" />
        <h3>Aman Das</h3>
        <h4>Software Engineer | Automotive Enthusiast | MN</h4>{" "}
      <p>Welcome to my personal Website!</p>
      <p>
        <a className="Home-link" href="https://github.com/ad-das">
          GitHub
        </a>
        {space}
        <a
          className="Home-link"
          href="https://linkedIn.com/in/aman-das-087b8268"
        >
          LinkedIn
        </a>{" "}
        {space}
        <a className="Home-link" href="https://www.youtube.com/user/greg3779">
          YouTube
        </a>
      </p>
    </div>
  );
};
